.header-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    min-height: 230px;
    margin: 0px 50px;

    @media (max-width: 480px) {
        margin: 0px 10px;
        min-height: 10vh;
    }
}