*{
    font-family: 'Questrial', sans-serif;
    scroll-behavior: smooth;
}

body{
    overflow-x: hidden;
}
  
.main-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    width:100vw;
    min-height:100vh;
    background-color: #111111;
}

.main-holder{
    width:70vw;

    @media (max-width: 480px) {
        width:95vw;
        font-size:0.8em;
        line-height: 1.2;
    }

    @media only screen and (min-width: 481px) and (max-width: 768px){
        width:95vw;
        font-size:0.85em;
        line-height: 1.3;
    }

    @media only screen and (min-width: 769px) and (max-width:1280px){
        width:95vw;
        font-size:0.9em;
    }

    @media only screen and (min-width: 1281px) and (max-width:1699px){
        width:90vw;
        font-size:0.95em;
    }
}

.sectionTitle {
    font-size: 2em; 
    margin-bottom: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: lighter;
    color:#E0E0E0;
}
  