
.login-data-container{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top:30px;
    margin-bottom:30px;
}
      
.login-data-title{
        color:#E0E0E0;
        font-weight: 100;

}
      
.login-data-body{
        display:flex;
        flex-direction: column;
}
      
.login-data-item{
        display:flex;
        width:200px;
        justify-content: space-around;
        padding:1px 0px;
        align-items: center;

        input{
            width:70%;
        }

        svg{
            color:#E0E0E0;
        }

        svg:hover{
            cursor: pointer;
            color:#3A3856;
            transform: scale(1.3);
            transition: transform 0.3s ease-in;
        }
}
      
.copy-item{
        background-color:#E0E0E0;
        padding:3px 6px;
        text-align: center;
        font-size:0.7em;
}
