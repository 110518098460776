.footer{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.disclaimer{
    display:flex;
    justify-content: center;
    color:#E0E0E0;
    border-top:1px solid #E0E0E0;
    padding-top:19px;
    width:50%;

    @media (max-width: 360px){
        font-size: 0.75em;
        width:100%;
    }

    @media only screen and (min-width: 361px) and (max-width: 480px){
        width:95%;
        font-size: 0.8em;
    }

    @media only screen and (min-width: 481px) and (max-width: 768px){
        width:80%;
    }

    @media only screen and (min-width: 769px) and (max-width:1280px){
        width:65%;
    }
}