.biography-container{
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
    width:70vw;
    margin-top:50px;
    margin-bottom:50px;

    .biography-title{
        font-size: 2em;
        margin-bottom: 50px;
        border-radius: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: lighter;
        color: #E0E0E0
    }

    .biography-body{
        display:flex;
        flex-wrap: wrap;
        font-size:1.2em;
        color:#A0A0A0;
    }

    .biography-text{
        width:68%;
    }

    .biography-paragraph{
        line-height: 180%;
        padding-bottom: 2rem;

        a{
            text-decoration: none;
            color:#E0E0E0;

            &:hover{
                cursor: pointer;
                transition: all 0.25s ease-out 0s;
                color:#3A3856;
            }
        }
    }

    .biography-contact{
        width:28%;
        display:flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 5px;
    
        .contact-title{
            color: #E0E0E0;
            font-size:1.5em;
            margin-bottom: 20px;
        }

        .contact-item{
            padding-bottom:5px;
        }

        a{
            text-decoration: none;
            color:#A0A0A0;
            margin-bottom: 7px;

            &:hover{
                color:#3A3856;
            }
        }
    }

    @media (max-width: 480px) {
        width: 95vw;

        .biography-body{
            flex-direction: column;
            font-size:1.15em;
        }

        .biography-text{
            width:95%;
        }

        .biography-contact{
            width:95%;
            align-items: center;
            margin-top:30px;
        }
    }

    @media only screen and (min-width: 481px) and (max-width: 768px){
        width:95vw;

        .biography-body{
            justify-content: space-around;
        }
        
        .biography-text{
            width:60%;
        }
    }

    @media only screen and (min-width: 769px) and (max-width:1280px){
        width:95vw;
    }

    @media only screen and (min-width: 1281px) and (max-width:1699px){
        width:90vw;
    }
}

.highlighted{
    color:#E0E0E0;
    font-size:1.05em;
}

.download-cv{
    button{
        background-color: inherit;
        text-decoration: none;
        color:#A0A0A0;
        margin-bottom: 7px;
        border:none;
        font-size:1em;
        margin-top:3px;

        svg{
            position: relative;
            left:7px;
        }

        &:hover{
            cursor: pointer;
            color:#3A3856;
        }
    }
}